.market-pairs {
  border: 1px solid #e0e3eb;
  padding-top: 14px;
  border-radius: 2px;
}
.market-pairs .input-group {
  padding: 0 14px;
}
.market-pairs span {
  background: transparent;
}

.market-pairs input {
  border-color: #e0e3eb;
  border-left: 0;
  font-weight: 300;
}
.market-pairs input:focus {
  box-shadow: none;
  border-color: #e0e3eb;
}
.market-pairs .nav {
  margin-top: 14px;
  background: #f5f9fc;
  border-bottom: transparent;
}

.market-pairs .nav-link {
  color: #4a4a4a;
  padding: 10px 13px;
}
.market-pairs .nav-link:hover {
  border-color: transparent;
}
.market-pairs .nav-link.active {
  color: #007bff;
  background: transparent;
  border-color: transparent;
}

.table {
  margin-bottom: 0;
}
.table td {
  font-size: 13px;
  padding: 10px 18px;
  cursor: pointer;
}

.market-pairs tbody tr td i {
  color: #75869696;
}
.star-active tbody tr td i {
  color: #007bff;
}
.market-pairs thead tr th,
.market-pairs tbody tr td {
  font-weight: 400;
}
.market-pairs table{
  width: 100%; /* Set the desired width for the table */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  height: 633px;

}
.last-row td {
  border-bottom: 1px solid #e0e3eb; /* Adjust the color and style as needed */
}
